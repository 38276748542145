import { Box, useTheme } from '@mui/material';
import React from 'react';
import { BusinessStyledTypograhpy } from '../RoleBusinessLocations/RoleBusinessLocations';
import { IOfferFilterItem } from './types';

interface IFilterItem {
  filterItem: IOfferFilterItem
  isActive: boolean;
  setFilterStatus: React.Dispatch<React.SetStateAction<IOfferFilterItem>>
}

function FilterItem({ filterItem, isActive, setFilterStatus }: IFilterItem) {
  const theme = useTheme();

  return (
    <Box>
      <BusinessStyledTypograhpy
        children={filterItem.label}
        display={'inline-block'}
        sx={{
          color: isActive ? theme.palette.primary.main : 'unset',
          fontWeight: isActive ? 700 : 500,
          cursor: 'pointer',
        }}
        onClick={() => setFilterStatus(filterItem)}
      />
    </Box>
  );
}

export default FilterItem;