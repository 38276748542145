import dayjs from 'dayjs';
import { useState } from 'react';
import {
  Box,
  Divider,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import FilterItem from './FilterItem';
import { HEAD_CELLS, OFFER_FILTERS } from './constants';
import { IOfferFilterItem } from './types';
import Show from '../components/Show/Show';
import {
  BusinessStyledTypograhpy,
  StyledTableCell,
} from '../RoleBusinessLocations/RoleBusinessLocations';
import DynamicTableHead from '../components/DynamicTableHead';
import DynamicTablePagination from '../components/DynamicTablePagination';
import { useDynamicTableActions } from 'src/hooks/useDynamicTableActions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useGetMarketPerformanceOffersAPI } from 'src/api/marketplacePerformance';
import DateRangePicker from './DateRangePicker';
import { useGeneralFilters } from 'src/hooks/useGeneralFIlters';

const MarketplacePerformance = () => {
  const [filterStatus, setFilterStatus] = useState<IOfferFilterItem>(OFFER_FILTERS[0]);
  const offers = useSelector((state: RootState) => state.marketplacePerformanceOffers);
  const {
    sort,
    order,
    orderBy,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
  } = useDynamicTableActions('title');
  const theme = useTheme();

  const dateRangeFilters = useGeneralFilters({
    initEndDate: dayjs(),
    initStartDate: dayjs('2023-07-01')
  });
  const { startDate, endDate } = dateRangeFilters;

  const { isLoading } = useGetMarketPerformanceOffersAPI({
    sort,
    page,
    size: rowsPerPage,
    statusList: filterStatus.value,
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
  });

  return (
    <>
      <Typography variant="h3" paragraph>
        Marketplace Perfomance:{' '}
        <Typography variant="h3" component="span" color={theme.palette.primary.main}>
          {offers.totalElements}
        </Typography>
      </Typography>
      <Box>
        <Box
          sx={{
            height: 36,
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
            mt: 3,
          }}
        >
          <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} gap={'20px'}>
            {OFFER_FILTERS.map((filter) => (
              <FilterItem
                key={filter.label}
                filterItem={filter}
                isActive={filterStatus.value === filter.value}
                setFilterStatus={setFilterStatus}
              />
            ))}
          </Box>
        </Box>
        <DateRangePicker {...dateRangeFilters} />
      </Box>
      <Show condition={isLoading}>
        <LinearProgress />
      </Show>
      <Show
        condition={!!offers.content.length}
        or={
          <BusinessStyledTypograhpy variant="h4" paragraph>
            There are no offers
          </BusinessStyledTypograhpy>
        }
      >
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size={'medium'}>
              <DynamicTableHead
                order={order}
                orderBy={orderBy}
                headCells={HEAD_CELLS}
                rowsCount={offers.totalElements}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {offers.content.map((offer) => (
                  <TableRow key={offer.id}>
                    <StyledTableCell align="center">{offer.title}</StyledTableCell>
                    <StyledTableCell align="center">{offer.business}</StyledTableCell>
                    <StyledTableCell align="center">{offer.opened}</StyledTableCell>
                    <StyledTableCell align="center">{offer.purchased}</StyledTableCell>
                    <StyledTableCell align="center">{offer.activated}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <DynamicTablePagination
            page={page}
            totalPages={offers.totalPages}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
          />
        </Paper>
      </Show>
    </>
  );
};

export default MarketplacePerformance;
